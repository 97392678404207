.sku-plannings-list {
  .k-grid-content {
    overflow-y: auto;
  }
}

.sku-bottom-charts {
  position:fixed;
}

.hide-display {
  display: none;
}
.sku-grid-title{
  display:flex;
  align-items: center;
  margin:0 10px 0 0;
}

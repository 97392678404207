$symbol_cus: '/';
.h {
  &-screen {
    height: 100vh !important;
    @for $i from 1 through 4 {
      &-#{$i} {
        height: #{25 * $i}vh !important;
      }
    }
  }
}

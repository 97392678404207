// @font-face {
//   font-family: "Scto Grotesk A";
//   src: url("/assets/fonts/Scto Grotesk A Bold.otf") format("opentype");

//   font-weight: normal;
//   font-style: normal;
// }
.custom_modal {
  transform: translateY(-2%);
  -webkit-transform: translateY(-2%);
  -moz-transform: translateY(-2%);
  -ms-transform: translateY(-2%);
  -o-transform: translateY(-2%);
  .modal-content {
    // min-width: 71.5625rem;
    .flex-1 {
      flex: 1;
    }
    .modal-body {
      max-height: 70vh;
      // overflow: auto;
      padding: 0px;
    }
    padding: 0px;
    overflow: hidden;
    .header-wrapper {
      position: relative;
      background-color: #f7f7fa;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      outline: 1px solid #f0f0f0;
      span {
        font-size: 14px;
        font-weight: 600;
        color: #080255;
      }
      > span {
        font-family: inherit;
        display: inline-block;
        position: absolute;
        left: 16px;

        span {
          margin-left: 4px;
        }
      }
      .title {
        font-weight: 600;
        padding: 6px 10px;
        margin-bottom: 0px;
        background-color: #e6e6ee;
        border-radius: 3px;
        font-size: 14px;
        line-height: 18px;
        color: #080255;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
      }
    }
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
    .category_review_wrapper {
      display: grid;
      row-gap: 0.5rem;
      padding-top: 12px;
      height: 50vh;
      .catergory_review_list-content {
        & > div {
          padding: 1rem;
          display: grid;
          row-gap: 0.5rem;
        }
      }
      .category_review_item-wrapper {
        padding: 4px 16px;
        &:nth-child(2n) {
          .category_review_item {
            background-color: rgb(230, 230, 238, 0.3);
          }
        }
      }

      .category_review_item {
        flex-wrap: nowrap;
        background-color: #fff;
        // &:nth-child(2n) {
        //   background-color: rgb(230, 230, 238, 0.3);
        // }
        & > div:nth-child(1) {
          padding-left: 0px;
          padding-right: 0px;
        }
        outline: 1px solid #f0f0f0;
        border-radius: 4px;
        padding: 8px;
        width: 100%;
        .col-lg-5 {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        p {
          font-size: 14px;
          margin-bottom: 4px;
          line-height: 1rem;
          color: #0c0c0c;
        }
        div {
          span {
            display: inline-block;
            font-size: 0.75rem;
            line-height: 1rem;
          }
        }

        img {
          height: 44px;
          width: 44px;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
        }
      }
    }
  }
  .footer_wrapper {
    display: flex;
    justify-content: space-between;
    background-color: #f7f7fa;
    align-items: center;
    padding: 16px;
    outline: 1px solid #f0f0f0;
    button {
      font-size: 14px;
      line-height: 18px;
      padding: 0.375rem 0.75rem;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      display: flex;
      align-items: center;
      color: #080255;
    }
    button:nth-child(1) {
      color: #080255;
      background-color: transparent;
      border: none;
      &:focus {
        outline: none;
        border: none;
        background-color: #f0f0f0;
      }
      &:hover {
        background-color: #e6e6ee;
      }
    }

    button:nth-child(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 4.75rem;
      border: none;
      // padding: 0px 2px;
      background-color: rgb(8, 2, 85);
      color: white;
      &:focus {
        outline: none;
        border: none;
        box-shadow: 0 0 5px rgba(8, 2, 85, 0.8);
      }
      &:hover {
        background-color: #060244;
      }
    }
  }
}
@media (min-width: 1200px) {
  .modal-lg,
  .modal-xl {
    max-width: 1100px;
  }
}
.custom_message_modal {
  .modal-content {
    padding: 16px !important;
  }
  max-width: 22.875rem;

  .flex {
    display: flex;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .items-center {
    align-items: center;
  }
  .margin-b-10 {
    margin-bottom: 10px;
  }
  .img-content {
    padding: 0px 0px 20px 0px;
    // margin-bottom: 10px;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    color: #080255;
    h5 {
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      margin-bottom: 0px;
    }
  }
  .message-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1.5rem;
    p {
      text-align: center;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #080255;
      margin-bottom: 0px;
    }
  }
  .footer-btn {
    display: flex;
    justify-content: space-between;
    column-gap: 0.5rem;
    button {
      height: 2.5rem;
      padding: 6px 16px;
      width: 100%;
      border-radius: 8px;
      background-color: #080255;
      color: white;
      font-size: 14px;
      display: flex;
      height: 40px;
      padding: 6px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      font-style: normal;
      font-weight: 500;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
    }
    button:nth-child(1) {
      border: none;
      background-color: #e6e6ee;
      color: #080255;
      &:hover {
        background-color: #ceccdd;
      }
    }
    button:last-child {
      border: none;
      background-color: #080255 !important;
      color: white !important;
      &:hover {
        background-color: #060244 !important;
        color: white !important;
      }
    }
  }
}
.Toastify__toast-container:has(.toast-custom) {
  width: 320px !important;
}
.Toastify__toast-container:has(.toast-custom) .Toastify__toast {
  padding: 10px 8px 10px 20px;
  border-radius: 6px !important;
  width: 320px !important;
  min-height: 50px;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
}
.Toastify__toast-container:has(.toast-custom)
  .Toastify__toast
  .Toastify__toast-body {
  padding: 0px !important;
}
.Toastify__toast-container:has(.toast-custom)
  .Toastify__toast
  .Toastify__progress-bar--default {
  // display: none !important;
  background: rgba(255, 255, 255, 0) !important;
}
.toast-custom {
  // --content-width: 500px;
  background-color: #080255;
  .toast-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .toast-info {
      display: inline-block;
      // margin: 0px 20px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
    .close-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      margin-left: 8px;
      margin-right: 0px;
      cursor: pointer;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      &:hover {
        background-color: #393577;
      }
    }
  }
}

//mapping-alert

.mapping-alert {
  padding: 0.875rem;
  border-radius: 8px;
  background-color: #f4eaff;
  border: 1px solid #942ffb;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  margin-bottom: 0.625rem;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    span {
      margin-right: 0.75rem;
      display: inline-block;
      position: relative;
      top: -0.25rem;
      height: 20px;
    }
    p {
      display: inline-block;
      font-size: 1rem;
      font-weight: 500;
      color: #942ffb;
      line-height: 1.25rem;
      margin-bottom: 0px;
    }
  }
  .info {
    margin-left: 2.125rem;

    p {
      line-height: 1.25rem;
      margin-bottom: 0px;
      font-size: 14px;
    }
    span {
      margin-top: 0.625rem;
      display: inline-block;
    }
  }
}
@media screen and (min-width: 1400px) {
  .modal-content {
    // min-width: 71.5625rem;
  }
}
.Tooltip {
  height: 100px;
  width: 100px;
  background-color: #000;
  color: #e6e6ee;
  z-index: 9999;
}
.highlight-text {
  color: #942ffb;
  cursor: pointer;
  font-weight: 500;
}
.progress-shape {
  display: flex;
  align-items: center;
  .percent {
    font-size: 0.75rem;
    margin-left: 1rem;
    font-weight: bold;
  }
}
.progress-horizontal {
  height: 5px;
}
.progress-track {
  height: 100%;
  width: 100%;
  border-radius: 6.25rem;
  -webkit-border-radius: 6.25rem;
  -moz-border-radius: 6.25rem;
  -ms-border-radius: 6.25rem;
  -o-border-radius: 6.25rem;
}
.progress-track-inner {
  height: 100%;
  background-color: #3bb346;
  border-radius: 0.3125rem;
  transition: width 0.3s;
  transition-timing-function: cubic-bezier(0.62, 0.05, 0.36, 0.95);
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
}

.progress-wrapper {
  margin-bottom: 1rem;
  margin-top: 0.625rem;
  padding: 1rem;
  border: 1px solid #d1d2db;
  border-radius: 0.375rem;
  .progress-content {
    margin-bottom: 1rem;
    display: flex;
    .clip-icon {
      height: 2.5rem;
      width: 2.5rem;
      margin-right: 1rem;
      background-color: #f8f9ff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.375rem;
      -webkit-border-radius: 0.375rem;
      -moz-border-radius: 0.375rem;
      -ms-border-radius: 0.375rem;
      -o-border-radius: 0.375rem;
    }
    .progress-info {
      font-size: 1rem;
      line-height: 1.125rem;
      p {
        margin-bottom: 5px;
        span {
        }
      }
    }
    .close-icon {
      cursor: pointer;
      margin-left: auto;
      // position: relative;
      // height: 40px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      // width: 40px;
      // top: -4px;
      // padding: 0.25rem;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      background-color: transparent;
      border: none;
      &:hover {
        // background-color: #f0f0f0;
      }
    }
  }
}
.close-icon-1 {
  cursor: pointer;
  margin-left: auto;
  position: relative;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  padding: 0.25rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: transparent;
  border: none;
  &:hover {
    // background-color: #f0f0f0;
  }
}
.custom-btn {
  border-radius: 13px;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  border: 1px solid #942ffb;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px rgba(23, 26, 31, 0.12),
    0px 0px 1px 0px rgba(23, 26, 31, 0.07) !important;
  svg {
    position: relative;
    top: -1px;
  }
  &:hover {
    background: var(--primary-500-main, #942ffb);
    box-shadow: 0px 0px 2px 0px rgba(148, 47, 251, 0.3);
    svg {
      path {
        stroke: #fff;
      }
    }
  }
}

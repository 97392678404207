/* @import "_core.scss"; */

.na.k-grid a {
  color: #3b3e66!important;
}

.na.k-grid a:hover {
  color: #4191ff!important;
}

.na.k-grid {
  border-width: 0!important;
}

.na.k-grid-header
{
  padding: 0!important;
}

// .na .k-grid-content
// {
//   overflow-y: visible!important;
// }

.na.k-grid .k-grid-content
{
  overflow-y: visible!important;
  padding-right: 20px;
}

/* .na-report.k-grid {
  margin: (-$layout-spacer) (-$layout-spacer)
}
 */

.na.k-grid th {
  border: 0px!important;
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 0.875rem;
  color: #3b3e66!important;
}

.na.k-grid td {
  border: 0px!important;
}

.k-form {
  color: #3b3e66!important;
}

.overflowVisible .k-window-content {
  overflow: visible!important;
}

.k-overlay {
  width: calc(100% + 7rem)!important;
  height: calc(100% + 7rem)!important;
  left: -3.5rem!important;
  min-height:100vh;
  z-index:9999;
}

.noPaddingLeft {
  padding-left: 0!important;
}

.app-wrapper{
  overflow: visible!important;
}

.outScrolled {
  position: sticky;
  top:100px;
  left:0;
  width:100%;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  margin-left: -6px;
}

.scrolled {
  flex: 1 1 auto;
  padding-left: calc(1rem + 6px);
  margin-left: -16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stickyMenuItem{
  padding-bottom: 10px;
}

.scrolled ul {
  padding-left: calc(1rem - 6px);
  border-left: 1px solid rgb(222, 222, 222);
  padding-top: 0px;
  padding-bottom: 0px;
  display: block;
  list-style: none;
  margin-top: 0px;
  margin-bottom: 0px;
  position: relative;
}

.scrolled li {
  position: relative;
  line-height: 1;
  word-break: break-word;
  font-size: 18px;
  color: #cecece;
  font-weight: 500;
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.scrolled li:hover{
  color:#3c44b1;
}

.activeStickyMenu {
  position: relative;
  line-height: 1;
  word-break: break-word;
  font-size: 18px;
  font-weight: 500;
  color: #3c44b1!important;
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.activeStickyMenu::before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 6px;
  transform: translateX(-14px);
  background-color: #3c44b1;
  height: 100%;
  width: 7px;
}

.form-margin{
  margin: -3rem 0 -3rem;
}

.ps-primaryButtonColor {
  color: white !important;
  background-color: #3c44b1 !important;
}

.product-modal {
  max-width: calc(100vw - 320px) !important;
  margin: 1.75rem 3vw 1.75rem calc(3vw + 320px) !important;
  height:calc(100vh - 3.5rem) !important;
}

.product-modal-collapsed {
  max-width: calc(100vw - 100px) !important;
  margin: 1.75rem 3vw 1.75rem calc(3vw + 100px) !important;
  height:calc(100vh - 3.5rem) !important;
}

.modal-content {
  padding:20px 10px 10px;
  // height: 100%;
}

.modal-content-height {
  max-height: 66vh!important;
}

body>.k-animation-container-shown {
  z-index:10000!important;
}

.k-dropdown .k-dropdown-wrap{
  background-color: white !important;
}

.productLineCell{
  transition: ease-in-out 0.15s;
}

.productLineCell:hover{
  cursor: pointer;
  // transform: translateY(-5px);
  color: #4191ff!important;
}

.btn-pointer {
  cursor: pointer;
}

.customTitleSticky {
  position: sticky;
  top:0;
  left:0;
  z-index:99999;
  box-shadow:0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05),
}
body {
  --dsz-grey-8: 46, 50, 56;
  --dsz-color-fill-0: rgba(var(--dsz-grey-8), 0.05);
  --dsz-color-active: #942ffb;
  --dsz-color-bg-active: #f4eaff;
}

.dsz-tree-select {
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #d1d2db;
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  font-weight: 400;
  // transition: all 0.3s ease-in-out;
  //background-color: var(--dsz-color-fill-0);
  display: inline-flex;
  align-items: center;
  position: relative;
  // cursor: pointer;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  .tag-text-popover {
    transform: scaleY(0);
    position: absolute;
    z-index: 9999;
    //opacity: 0;
    background-color: #fff;
    border: 1px solid #d1d2db;
    padding: 8px;
    font-size: 12px;
    line-height: 14px;
    color: #080255;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .tag-text-popover-active {
    opacity: 1;
    transform: scaleY(1);
  }

  .tree-select-placeholder {
    flex-grow: 1;
    // color: #942FFB;
    margin-left: 16px;
    color: #d1d6e5;
    white-space: nowrap;
    overflow: hidden;
    // border-right: 1px solid hsl(0, 0%, 90%);
  }
  .tree-select-selection {
    font-size: 14px;
    line-height: 20px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    overflow: hidden;
    padding-left: 12px;
    padding-right: 0;
    cursor: pointer;
    position: relative;
    // border-right: 1px solid hsl(0, 0%, 90%);
  }
  .tree-select-arrow {
    cursor: pointer;
    min-width: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: 10px;
    .tree-icon-chevron-down {
      display: inline-block;
      color: #080255;
    }
  }
}
.dsz-ai-tree-select {
  min-height: 44px;
  border: none;
  outline: none;
  background-color: #fee2e2;
  .tree-icon-chevron-down {
    display: inline-block;
    color: #b91c1c;
  }
}
.dsz-tree-select-focus {
  border: 1px solid #d1d2db;
  border-color: var(--dsz-color-active);
}
.dsz-tree-select-focus-sub {
  background-color: #e6e6ee;
  // border-color: #000;
  border: 1px solid #161618;
}

.tag-group {
  position: relative;
  width: 100%;
}
.dsz-tree-select-tag {
  display: flex;
  justify-content: center;
  width: 100%;
  -webkit-line-clamp: 3;
  // background-color: #f4f4f4;
  // border: 1px solid #e9e9e9;
  padding: 4px 8px;
  border-radius: 2px;
  height: 24px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 400;
  line-height: 1rem;
  margin: 1px 2px;
  .tag-content-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tag-content {
    color: #942ffb;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    -webkit-box-orient: vertical;
  }
  .tag-close {
    padding-left: 4px;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.dsz-ai-tree-select-tag {
  color: #b91c1c;
  .tag-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.dsz-portal-wrapper {
  // position: absolute;
  z-index: 9999;
}

.dsz-portal-ai {
  background-color: #fff;
  z-index: 9999;
  transform-origin: 150px 0px;
  animation-fill-mode: forwards;
  border-radius: 0.375rem;
}
.dsz-portal {
  //opacity: 0;
  position: absolute;
  background-color: #fff;
  z-index: 9999;
  transform-origin: 150px 0px;
  animation-fill-mode: forwards;
  border-radius: 0.375rem;
  //transition: all 0.3s ease-in-out;
  top: 50px;
}
.dsz-popver-wrapper {
  overflow: hidden;
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 8px;
  background-color: #fff;
  // box-shadow: 0px 4px 6px 4px rgba(231, 231, 231, 0.9);
  box-shadow: 0px 2px 6px 2px rgba(231, 231, 231, 0.5);
  z-index: 1030;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  opacity: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  .dsz-tree-select-popover {
    // height: 450px;
  }
  .tree-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    .categories-content {
      position: relative;
      overflow: auto;
      max-height: 420px;
    }
    .search-tree-item-text {
      cursor: pointer;
    }
    .sub-title {
      padding: 8px 20px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #6b6799;
    }
  }
  .tree-search-wrapper {
    padding: 8px 16px 16px 16px;
    // padding: 0px;
    //   padding: 8px 0px;
    border-bottom: 1px solid #f0f0f0;

    .tree-input {
      padding: 3px 2px;
      display: flex;
      align-items: center;
      background-color: rgba(230, 230, 238, 1);
      border-radius: 4px;

      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      .input-prefix {
        margin: 0px 8px;
        cursor: auto;
      }
      .search-input {
        height: 32px;
        flex: 1;
        border: none;
        outline: none;
        background: inherit;
        border-radius: 4px;
        border-color: transparent;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
      }
      input[type="text"]:focus {
        border-color: var(--dsz-color-active) !important;
      }
      .input-suffix {
        margin: 0px 12px;
        .dsz-icon-close {
          cursor: pointer;
          &:hover svg path {
            fill: #6b6799;
          }
        }
      }
    }
    .tree-input-foucs {
      outline: 1px solid var(--dsz-color-active);
    }
  }
}
.dsz-popver-wrapper-show {
  opacity: 1;
}
.dsz-tree-option-list {
  flex: 1;
  overflow: auto;
  padding: 8px 0px;
  .dsz-tree-option {
    position: relative;
    padding: 8px 16px;
    list-style: none;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    transform: scale(1, 1);
    &:hover {
      background-color: #f0f0f0;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
    }
    .dsz-tree-option-indet {
      .dsz-tree-option-indent-unit {
        display: inline-block;
        width: 16px;
      }
    }
    .tree-item-checkbox {
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 20px;
      margin-right: 8px;

      input[type="checkbox"] {
        cursor: pointer;
        position: relative;
        width: 18px;
        height: 18px;
        font-size: 12px;
      }

      input[type="checkbox"]::after {
        position: absolute;
        top: 0;
        color: #000;
        width: 18px;
        height: 18px;
        display: inline-block;
        visibility: visible;
        padding-left: 0px;
        text-align: center;
        content: " ";
        border-radius: 3px;
      }

      input[type="checkbox"]:checked::after {
        content: "✓";
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        background-color: #942ffb !important ;
      }
    }
    .dsz-tree-option-label {
      color: #080255 !important;
      // cursor: pointer;
      cursor: default;
      flex: 1;
      margin-right: 8px;
    }
    .dsz-tree-option-icon {
      margin-left: auto;
      margin-right: 8px;
      background-color: transparent;
      transform: rotate(0deg);
      transition: transform 0.3s ease;
      -webkit-transition: transform 0.3s ease;
      -moz-transition: transform 0.3s ease;
      -ms-transition: transform 0.3s ease;
      -o-transition: transform 0.3s ease;
    }
    .dsz-tree-option-expand-icon {
      transform: rotate(90deg);
    }
  }
  .search-head-title {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    color: #6b6799;
  }
  .search-tree-item-text {
    color: #080255 !important;
    padding: 8px 16px;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  .search-tree-item-text-active {
    background-color: var(--dsz-color-bg-active);
    &:hover {
      background-color: var(--dsz-color-bg-active) !important;
    }
  }
}
.dsz-ai-tree-option-list {
  padding: 0px 10px;
  span {
    margin-right: 8px;
  }
  .ai-tree-item-text {
    color: #942ffb;
    padding: 8px 10px;
    cursor: pointer;
    border-radius: 4px;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    &:hover {
      background-color: #f0f0f0;
    }
    & > span:nth-child(1) {
      margin-right: 8px;
    }
  }
  .ai-tree-item-text-active {
    background-color: var(--dsz-color-bg-active);
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    &:hover {
      background-color: var(--dsz-color-bg-active) !important;
    }
  }
}

.dsz-portal-show {
  opacity: 1;
}
